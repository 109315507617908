<template>
  <div id="home-main">
    <Header :height="55"></Header>
    <div class="form-apply" style="padding: 20px 0; color: #606266;">
      <div>
        <span style="color: #303133; cursor: pointer;" @click="goBack">首页</span>
        <span style="padding: 0 4px;"> / </span>
        <span>入会申请</span>
      </div>
    </div>
    <div class="article-box form-apply" style="height: auto;" v-loading="pageLoading">
      <div class="title">中国交通运输协会入会申请表</div>
      <div v-if="state === 99 || formShow" class="form-box">
        <div class="title-content">
          <el-steps :active="Number(activeName - 1)" align-center>
            <el-step title="第一部分" description="企业信息"></el-step>
            <el-step title="第二部分" description="单位代表和联系人信息"></el-step>
            <el-step title="第三部分" description="资料提交"></el-step>
          </el-steps>
        </div>
        <el-tabs class="tab" v-model="activeName" tab-position="left">
          <el-tab-pane name="1">
            <div class="topTitle">
              <span> </span>
              <span>第一部分 企业信息</span>
            </div>
            <el-form label-width="100px">
              <el-form-item label="单位性质" required>
                <el-radio-group v-model="unitNature" @change="unitNatureChange">
                  <el-radio :label="1">国企</el-radio>
                  <el-radio :label="2">事业</el-radio>
                  <el-radio :label="3">合资</el-radio>
                  <el-radio :label="4">民营</el-radio>
                  <el-radio :label="5">社团</el-radio>
                  <el-radio :label="6">其他</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
            <!-- 其他 -->
            <el-form v-show="unitNature != 5" :model="addForm1" :rules="addForm1Rules" ref="addForm1" label-width="100px" >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="单位名称" required>
                    <el-input v-model="userInfo.unitName" disabled placeholder="请输入单位名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="统一社会信用代码" label-width="150px" required>
                    <el-input placeholder="请输入统一社会信用代码" v-model="userInfo.loginName" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-form-item label="行业性质" prop="unitPreservePo.unitEnterprisePo.industryNature" :rules="[ { required: true, message: '请选择行业性质', trigger: 'change' } ]">
                <el-radio-group v-model="addForm1.unitPreservePo.unitEnterprisePo.industryNature" @change="industryNatureChange">
                  <span style="font-size: 14px; color: #606266;">交通土建（</span>
                  <el-radio :label="1">桥梁工程</el-radio>
                  <el-radio :label="2">隧道工程</el-radio>
                  <el-radio :label="3" style="margin-right: 10px;">基础设施</el-radio>
                  <span style="font-size: 14px; color: #606266; padding-left: -10px;">）</span>
                  <el-radio :label="4">装备技术</el-radio>
                  <el-radio :label="5">运输综合</el-radio>
                  <el-radio :label="6">物流</el-radio>
                  <el-radio :label="7">其他</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item v-if="addForm1.unitPreservePo.unitEnterprisePo.industryNature == 7" label="其他行业性质" label-width="110px" prop="unitPreservePo.unitEnterprisePo.natureCounte" :rules="[ { required: true, message: '请输入其他行业性质', trigger: 'blur' } ]">
                <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.natureCounte" placeholder="请输入其他行业性质"></el-input>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="110px" label="出资筹建单位" prop="unitPreservePo.unitEnterprisePo.funderUnit" :rules="[{required: true, message: '请输入出资筹建单位'}]">
                <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.funderUnit" placeholder="请输入出资筹建单位"></el-input>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="110px" label="业务经营范围" prop="unitPreservePo.unitEnterprisePo.businessScope" :rules="[ { required: true, message: '请输入业务经营范围' } ]">
                <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.businessScope" type="textarea" placeholder="请输入业务经营范围" :autosize="{ minRows: 2, maxRows: 8 }" maxlength="1000" show-word-limit></el-input>
              </el-form-item>
              <!--  -->
              <el-form-item label="注册资金" prop="unitPreservePo.unitEnterprisePo.registeredFunds" :rules="[{required: true, message: '请输入注册资金',trigger: 'change'}]">
                <Merge @getValue="getValueFirst($event, 1)" :sum="addForm1.unitPreservePo.unitEnterprisePo.registeredFunds"></Merge>
              </el-form-item>
              <!--  -->
              <el-form-item label="注册时间" prop="unitPreservePo.unitEnterprisePo.registeredTime" :rules="[{required: true, message: '请选择注册时间'}]">
                <el-date-picker style="width: 100%" value-format="yyyy-MM-dd" v-model="addForm1.unitPreservePo.unitEnterprisePo.registeredTime" type="date" placeholder="请选择注册时间" />
              </el-form-item>
              <!--  -->
              <el-form-item label="年营业收入" prop="unitPreservePo.unitEnterprisePo.annualRevenue" :rules="[{required: true, message: '请输入年营业收入',trigger: 'change'}]">
                <Merge @getValue="getValueFirst($event, 2)" :sum="addForm1.unitPreservePo.unitEnterprisePo.annualRevenue"></Merge>
              </el-form-item>
              <!--  -->
              <el-form-item label="总资产" prop="unitPreservePo.unitEnterprisePo.totalFunds" :rules="[{required: true, message: '请输入总资产',trigger: 'change'}]">
                <Merge @getValue="getValueFirst($event, 3)" :sum="addForm1.unitPreservePo.unitEnterprisePo.totalFunds"></Merge>
              </el-form-item>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="职工人数" prop="unitPreservePo.unitEnterprisePo.stuffNumber" :rules="[{required: true, message: '请输入职工人数', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.stuffNumber" placeholder="请输入职工人数">
                      <template slot="append">人</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="资产负债率" prop="unitPreservePo.unitEnterprisePo.assetRetio" :rules="[{required: true, message: '请输入资产负债率', trigger: 'blur'}]">
                    <el-input placeholder="请输入资产负债率" v-model="addForm1.unitPreservePo.unitEnterprisePo.assetRetio">
                      <template slot="append">%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-form-item label="具有大专及以上学历的中高层管理人员比例" label-width="300px" prop="unitPreservePo.unitEnterprisePo.personnelProportion" :rules="[{required: true, message: '请输入具有大专及以上学历的中高层管理人员比例', trigger: 'blur'}]">
                <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.personnelProportion" placeholder="请输入具有大专及以上学历的中高层管理人员比例">
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
              <!--  -->
              <el-form-item label="通讯地址" prop="unitPreservePo.unitEnterprisePo.communicateAddress" :rules="[{required: true, message: '请输入通讯地址', trigger: 'blur'}]">
                <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.communicateAddress" placeholder="请输入通讯地址">
                </el-input>
              </el-form-item>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="邮政编码" prop="unitPreservePo.unitEnterprisePo.zipCode" :rules="[{required: true, message: '请输入邮政编码'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.zipCode" placeholder="请输入邮政编码">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="法人代表" prop="unitPreservePo.unitEnterprisePo.legalRepresentative" :rules="[{required: true, message: '请输入法人代表'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.legalRepresentative" placeholder="请输入法人代表">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="国籍" prop="unitPreservePo.unitEnterprisePo.nationality" :rules="[{required: true, message: '请输入国籍'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.nationality" placeholder="请输入国籍">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="职务" label-width="110px" prop="unitPreservePo.unitEnterprisePo.position" :rules="[{required: true, message: '请输入职务'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.position" placeholder="请输入职务">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="党组书记" prop="unitPreservePo.unitEnterprisePo.partySecretary" :rules="[{required: true, message: '请输入党组书记'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.partySecretary" placeholder="请输入党组书记">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="董事长" prop="unitPreservePo.unitEnterprisePo.chairman" :rules="[{required: true, message: '请输入董事长'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.chairman" placeholder="请输入董事长">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="总经理(校长)" label-width="110px" prop="unitPreservePo.unitEnterprisePo.generalManager" :rules="[{required: true, message: '请输入总经理(校长)'}]">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.generalManager" placeholder="请输入总经理(校长)">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-form-item label="副总经理(副校长)" label-width="140px" prop="unitPreservePo.unitEnterprisePo.deputyGeneralManager" :rules="[{required: true, message: '请输入副总经理(副校长)'}]">
                <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.deputyGeneralManager" placeholder="请输入副总经理(副校长)">
                </el-input>
              </el-form-item>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="网站" >
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.url" placeholder="请输入网站">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="微信公众号">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.wechatOffcialNo" placeholder="请输入微信公众号">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="微博" >
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.weibo" placeholder="请输入微博">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="抖音">
                    <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.tikTok" placeholder="请输入抖音">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="单位地址" prop="unitPreservePo.unitAddress" :rules="[{required: true, message: '请选择单位地址',trigger: 'change'}]">
                    <el-cascader style="width: 100%" :options="regionData" v-model="addForm1.unitPreservePo.unitAddress" @change="unitAddressChange($event, 'addForm1')" placeholder="请选择单位地址">
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="具体地址" prop="unitPreservePo.specificAddress" :rules="[{required: true, message: '请输入具体地址'}]">
                    <el-input v-model="addForm1.unitPreservePo.specificAddress" placeholder="请输入具体地址">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-form-item label="单位简介" prop="unitPreservePo.unitEnterprisePo.unitIntroduction" :rules="[{required: true, message: '请输入单位简介'}]">
                <el-input v-model="addForm1.unitPreservePo.unitEnterprisePo.unitIntroduction" type="textarea" placeholder="请输入单位简介（发展概况、主营业务及企业规模，可在第三部分另附材料）">
                </el-input>
              </el-form-item>
              <!--  -->
              <el-form-item label-width="200px" label="主要参加哪个分支机构活动" prop="unitPreservePo.embranchmentId" :rules="[{required: true, message: '请选择主要参加哪个分支机构活动', trigger: 'change'}]">
                <el-select v-model="addForm1.unitPreservePo.embranchmentId" clearable style="width: 100%" @change="embranchmentIdChange($event, 'addForm1')">
                  <el-option v-for="(item, index) of embranchmentListqt" :key="index" :label="item.nickName" :value="item.institutionsId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="200px" label="关注其他分支机构" v-if="addForm1.unitPreservePo.embranchmentId">
                <el-select v-model="addForm1.unitPreservePo.embranchmentIds" multiple style="width: 100%" >
                  <el-option v-for="(item, index) of embranchmentListqt" :disabled="item.disabled" :key="index" :label="item.nickName" :value="item.institutionsId"></el-option>
                </el-select>
              </el-form-item>
              <!--  -->
              <el-form-item label="备注" >
                <el-input v-model="addForm1.unitPreservePo.fromRemarks" type="textarea" placeholder="请输入备注">
                </el-input>
              </el-form-item>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="填表人" prop="unitPreservePo.preparer" :rules="[{required: true, message: '请输入填表人'}]">
                    <el-input v-model="addForm1.unitPreservePo.preparer" placeholder="请输入填表人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="联系电话" prop="unitPreservePo.contactPhone" :rules="[{required: true, message: '请输入联系电话'}]">
                    <el-input placeholder="请输入联系电话" v-model="addForm1.unitPreservePo.contactPhone"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="注" required>
                <div>
                  请填写完成后<span class="text-btn" @click="download('addForm1')">点击此处</span>下载pdf文件后签字并加盖公章，在第三部分资料上传进行提交
                </div>
                <div>纸质材料原件（一份）邮寄至：北京市西城区广安门内大街315号信息大厦B座6层605室，马倩，17710420316，010-63691467</div>
              </el-form-item>
            </el-form>
            <!-- 社团 -->
            <el-form v-show="unitNature == 5" :model="addForm1" :rules="addFormARules" ref="addFormA" label-width="110px">
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="社会团体名称" required>
                    <el-input v-model="userInfo.unitName" disabled placeholder="请输入社会团体名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="统一社会信用代码" label-width="150px" required>
                    <el-input placeholder="请输入统一社会信用代码" v-model="userInfo.loginName" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="主管单位" prop="unitPreservePos.unitCommunityPo.unitCompetent" :rules="[{required: true, message: '请输入主管单位'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.unitCompetent" placeholder="请输入主管单位"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="成立时间" prop="unitPreservePos.unitCommunityPo.unitEstablishTime" :rules="[ { required: true, message: '请选择成立时间', trigger: 'change'}]">
                    <el-date-picker style="width: 100%" value-format="yyyy-MM-dd" v-model="addForm1.unitPreservePos.unitCommunityPo.unitEstablishTime" type="date" placeholder="请选择成立时间" />
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="届数" prop="unitPreservePos.unitCommunityPo.periodNumber" :rules="[{required: true, message: '请输入届数'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.periodNumber" placeholder="请输入届数"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="上次换届时间" prop="unitPreservePos.unitCommunityPo.lastChangePeriodTime" :rules="[ { required: true, message: '请选择上次换届时间', trigger: 'change'}]">
                    <el-date-picker style="width: 100%" value-format="yyyy-MM-dd" v-model="addForm1.unitPreservePos.unitCommunityPo.lastChangePeriodTime" type="date" placeholder="请选择上次换届时间" />
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="办公地址" prop="unitPreservePos.unitCommunityPo.unitOfficeAddress" :rules="[{required: true, message: '请输入办公地址'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.unitOfficeAddress" placeholder="请输入办公地址"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="邮政编码" prop="unitPreservePos.unitCommunityPo.unitZipCode" :rules="[{required: true, message: '请输入邮政编码'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.unitZipCode" placeholder="请输入邮政编码"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="电话" prop="unitPreservePos.unitCommunityPo.unitPhone" :rules="[{required: true, message: '请输入电话'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.unitPhone" placeholder="请输入电话"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="传真">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.unitFax" placeholder="请输入传真"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="单位会员" prop="unitPreservePos.unitCommunityPo.memberUnitNumber" :rules="[{required: true, message: '请输入数量'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.memberUnitNumber" placeholder="请输入数量">
                      <template slot="append">个</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="个人会员" prop="unitPreservePos.unitCommunityPo.memberPersonNumber" :rules="[{required: true, message: '请输入数量'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.memberPersonNumber" placeholder="请输入数量">
                      <template slot="append">个</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="分支机构" prop="unitPreservePos.unitCommunityPo.institutionNumber" :rules="[{required: true, message: '请输入数量'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.institutionNumber" placeholder="请输入数量">
                      <template slot="append">个</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="理事" prop="unitPreservePos.unitCommunityPo.councilNumber" :rules="[{required: true, message: '请输入数量'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.councilNumber" placeholder="请输入数量">
                      <template slot="append">人</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="常务理事" prop="unitPreservePos.unitCommunityPo.routineCouncilNumber" :rules="[{required: true, message: '请输入数量'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.routineCouncilNumber" placeholder="请输入数量">
                      <template slot="append">人</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="专职人员" prop="unitPreservePos.unitCommunityPo.fullTimeNumber" :rules="[{required: true, message: '请输入数量'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.fullTimeNumber" placeholder="请输入数量">
                      <template slot="append">人</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="联系人" prop="unitPreservePos.unitCommunityPo.contacts" :rules="[{required: true, message: '请输入联系人'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.contacts" placeholder="请输入联系人">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系电话" prop="unitPreservePos.unitCommunityPo.contactsPhone" :rules="[{required: true, message: '请输入联系电话'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.contactsPhone" placeholder="请输入联系电话">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="传真">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.contactsFax" placeholder="请输入传真">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-form-item label="办公地址" prop="unitPreservePos.unitCommunityPo.contactsAddress" :rules="[{required: true, message: '请输入办公地址'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.contactsAddress" placeholder="请输入办公地址">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="邮政编码" prop="unitPreservePos.unitCommunityPo.contactsZipCode" :rules="[{required: true, message: '请输入邮政编码'}]">
                    <el-input v-model="addForm1.unitPreservePos.unitCommunityPo.contactsZipCode" placeholder="请输入邮政编码">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <h4><span class="danger">*</span> 会长（理事长）</h4>
              <br />
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="姓名" prop="unitPreservePos.post1.name" :rules="[{required: true, message: '请输入姓名'}]">
                    <el-input v-model="addForm1.unitPreservePos.post1.name" placeholder="请输入姓名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="现职位" prop="unitPreservePos.post1.nowPosition" :rules="[{required: true, message: '请输入现职位'}]">
                    <el-input v-model="addForm1.unitPreservePos.post1.nowPosition" placeholder="请输入现职位">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="原职位" prop="unitPreservePos.post1.pastPosition" :rules="[{required: true, message: '请输入原职位'}]">
                    <el-input v-model="addForm1.unitPreservePos.post1.pastPosition" placeholder="请输入原职位">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <h4><span class="danger">*</span> 副会长（副理事长）</h4>
              <br />
              <div class="form-item-from-box">
                <el-table border :data="addForm1.unitPreservePos.post2" highlight-current-row style="width: 100%">
                  <el-table-column prop="name" label="姓名" >
                  </el-table-column>
                  <el-table-column prop="nowPosition" label="现职位" >
                  </el-table-column>
                  <el-table-column prop="pastPosition" label="原职位">
                  </el-table-column>
                  <el-table-column align="center">
                    <template slot="header" slot-scope="scope">
                      <el-button type="primary" circle size="mini" icon="el-icon-plus" @click="postFormAdd(2)"></el-button>
                    </template>
                    <template slot-scope="scope">
                      <el-link icon="el-icon-edit" type="primary" @click="postFormEdit(scope.row, scope.$index)">编辑</el-link>
                      <el-link icon="el-icon-delete" type="danger" style="margin-left: 20px;" @click="postFormDel(scope.row, scope.$index)">删除</el-link>
                    </template>
                  </el-table-column>
                </el-table>
                
              </div>
              <el-form-item label-width="0" prop="unitPreservePos.post2" :rules="[{required: true, message: '请添加副会长（副理事长）', trigger: 'change'}]">
                <el-checkbox-group v-model="addForm1.unitPreservePos.post2" v-show="false"></el-checkbox-group>
              </el-form-item>
              <h4><span class="danger">*</span> 秘书长</h4>
              <br />
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="姓名" prop="unitPreservePos.post3.name" :rules="[{required: true, message: '请输入姓名'}]">
                    <el-input v-model="addForm1.unitPreservePos.post3.name" placeholder="请输入姓名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="现职位" prop="unitPreservePos.post3.nowPosition" :rules="[{required: true, message: '请输入现职位'}]">
                    <el-input v-model="addForm1.unitPreservePos.post3.nowPosition" placeholder="请输入现职位">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="原职位" prop="unitPreservePos.post3.pastPosition" :rules="[{required: true, message: '请输入原职位'}]">
                    <el-input v-model="addForm1.unitPreservePos.post3.pastPosition" placeholder="请输入原职位">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <h4><span class="danger">*</span> 副秘书长</h4>
              <br />
              <div class="form-item-from-box">
                <el-table border :data="addForm1.unitPreservePos.post4" highlight-current-row style="width: 100%">
                  <el-table-column prop="name" label="姓名" >
                  </el-table-column>
                  <el-table-column prop="nowPosition" label="现职位" >
                  </el-table-column>
                  <el-table-column prop="pastPosition" label="原职位">
                  </el-table-column>
                  <el-table-column align="center">
                    <template slot="header" slot-scope="scope">
                      <el-button type="primary" circle size="mini" icon="el-icon-plus" @click="postFormAdd(4)"></el-button>
                    </template>
                    <template slot-scope="scope">
                      <el-link icon="el-icon-edit" type="primary" @click="postFormEdit(scope.row, scope.$index)">编辑</el-link>
                      <el-link icon="el-icon-delete" type="danger" style="margin-left: 20px;" @click="postFormDel(scope.row, scope.$index)">删除</el-link>
                    </template>
                  </el-table-column>
                </el-table>
                
              </div>
              <el-form-item label-width="0" prop="unitPreservePos.post4" :rules="[{required: true, message: '请添加副秘书长', trigger: 'change'}]">
                <el-checkbox-group v-model="addForm1.unitPreservePos.post4" v-show="false"></el-checkbox-group>
              </el-form-item>
              <!--  -->
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="社团地址" prop="unitPreservePos.unitAddress" :rules="[{required: true, message: '请选择社团地址',trigger: 'change'}]">
                    <el-cascader style="width: 100%" :options="regionData" v-model="addForm1.unitPreservePos.unitAddress" @change="unitAddressChange($event, 'addFormA')" placeholder="请选择单位地址">
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="具体地址" prop="unitPreservePos.specificAddress" :rules="[{required: true, message: '请输入具体地址'}]">
                    <el-input v-model="addForm1.unitPreservePos.specificAddress" placeholder="请输入具体地址">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-form-item label-width="200px" label="主要参加哪个分支机构活动" prop="unitPreservePos.embranchmentId" :rules="[{required: true, message: '请选择主要参加哪个分支机构活动', trigger: 'change'}]">
                <el-select v-model="addForm1.unitPreservePos.embranchmentId" clearable style="width: 100%" @change="embranchmentIdChange($event, 'addFormA')">
                  <el-option v-for="(item, index) of embranchmentListst" :key="index" :label="item.nickName" :value="item.institutionsId"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="200px" label="关注其他分支机构" v-if="addForm1.unitPreservePos.embranchmentId">
                <el-select v-model="addForm1.unitPreservePos.embranchmentIds" multiple style="width: 100%" >
                  <el-option v-for="(item, index) of embranchmentListst" :disabled="item.disabled" :key="index" :label="item.nickName" :value="item.institutionsId"></el-option>
                </el-select>
              </el-form-item>
              <!--  -->
              <el-form-item label="备注" >
                <el-input v-model="addForm1.unitPreservePos.fromRemarks" type="textarea" placeholder="请输入备注">
                </el-input>
              </el-form-item>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="填表人" prop="unitPreservePos.preparer" :rules="[{required: true, message: '请输入填表人'}]">
                    <el-input v-model="addForm1.unitPreservePos.preparer" placeholder="请输入填表人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="联系电话" prop="unitPreservePos.contactPhone" :rules="[{required: true, message: '请输入联系电话'}]">
                    <el-input placeholder="请输入联系电话" v-model="addForm1.unitPreservePos.contactPhone"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="注" required>
                请填写完成后<span class="text-btn" @click="download('addFormA')">点击此处</span>下载pdf文件后签字并加盖公章，在第三部分资料上传进行提交
                <div>纸质材料原件（一份）邮寄至：北京市西城区广安门内大街315号信息大厦B座6层605室，马倩，17710420316，010-63691467</div>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="2">
            <div class="topTitle">
              <span> </span>
              <span>第二部分 单位代表和联系人信息</span>
            </div>
            <el-form :model="addForm1" ref="addForm2" label-width="110px">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="单位代表姓名" prop="unitReprstPreservePo.name" :rules="[{required: true, message: '请输入单位代表姓名'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.name" placeholder="请输入单位代表姓名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="性别" prop="unitReprstPreservePo.sex" :rules="[{required: true, message: '请选择性别', trigger: 'change'}]">
                    <el-radio-group v-model="addForm1.unitReprstPreservePo.sex">
                      <el-radio :label="1">男</el-radio>
                      <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="出生年月" prop="unitReprstPreservePo.birthday" :rules="[{required: true, message: '请选择出生年月', trigger: 'change'}]">
                    <el-date-picker style="width: 100%" value-format="yyyy-MM-dd" v-model="addForm1.unitReprstPreservePo.birthday" type="date" placeholder="请选择出生年月" />
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="民族" prop="unitReprstPreservePo.national" :rules="[{required: true, message: '请输入民族', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.national" placeholder="请输入民族">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="籍贯" prop="unitReprstPreservePo.nativePlace" :rules="[{required: true, message: '请输入籍贯', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.nativePlace" placeholder="请输入籍贯">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="政治面貌" prop="unitReprstPreservePo.politicsStatus" :rules="[{required: true, message: '请输入政治面貌', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.politicsStatus" placeholder="请输入政治面貌">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="职务" prop="unitReprstPreservePo.position" :rules="[{required: true, message: '请输入职务', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.position" placeholder="请输入职务">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="职称" prop="unitReprstPreservePo.title" :rules="[{required: true, message: '请输入职称', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.title" placeholder="请输入职称">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="学历" prop="unitReprstPreservePo.education" :rules="[{required: true, message: '请输入学历', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.education" placeholder="请输入学历">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="学位" prop="unitReprstPreservePo.degree" :rules="[{required: true, message: '请输入学位', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.degree" placeholder="请输入学位">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="手机" prop="unitReprstPreservePo.phone" :rules="[{required: true, message: '请输入手机', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.phone" placeholder="请输入手机">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="办公电话" prop="unitReprstPreservePo.telephone" :rules="[{required: true, message: '请输入办公电话', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.telephone" placeholder="请输入办公电话">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="微信" prop="unitReprstPreservePo.wechat" :rules="[{required: true, message: '请输入微信', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.wechat" placeholder="请输入微信">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Email" prop="unitReprstPreservePo.email" :rules="[{required: true, message: '请输入Email', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.email" placeholder="请输入Email">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="传真" prop="unitReprstPreservePo.fax" :rules="[{required: true, message: '请输入传真', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.fax" placeholder="请输入传真">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-form-item label="通讯地址" prop="unitReprstPreservePo.mailingAddress" :rules="[{required: true, message: '请输入通讯地址', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.mailingAddress" placeholder="请输入通讯地址">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="邮政编码" >
                    <el-input v-model="addForm1.unitReprstPreservePo.zipCode" placeholder="请输入邮政编码">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <h4>个人工作简历</h4>
              <br />
              <div class="form-item-from-box">
                <el-table border :data="addForm1.unitReprstPreservePo.memberForResumes" highlight-current-row style="width: 100%">
                  <el-table-column prop="time" label="起始年月" >
                  </el-table-column>
                  <el-table-column prop="unit" label="单位" >
                  </el-table-column>
                  <el-table-column prop="position" label="职务">
                  </el-table-column>
                  <el-table-column prop="memberId" align="center">
                    <template slot="header" slot-scope="scope">
                      <el-button type="primary" circle size="mini" icon="el-icon-plus" @click="jobForm.dialogVisible = true"></el-button>
                    </template>
                    <template slot-scope="scope">
                      <el-link icon="el-icon-edit" type="primary" @click="memberForResumesEdit(scope.row, scope.$index)">编辑</el-link>
                      <el-link icon="el-icon-delete" type="danger" style="margin-left: 20px;" @click="memberForResumesDel(scope.row, scope.$index)">删除</el-link>
                      <!-- <span class="text-btn danger" @click="resumeItemClick1(2, scope.$index)"><i
                          class="el-icon-delete"></i></span> -->
                    </template>
                  </el-table-column>
                </el-table>
                
              </div>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="联系人姓名" prop="unitReprstPreservePo.contactName" :rules="[{required: true, message: '请输入联系人姓名', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactName" placeholder="请输入联系人姓名">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="职务" prop="unitReprstPreservePo.contactPosition" :rules="[{required: true, message: '请输入职务', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactPosition" placeholder="请输入职务">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="传真" prop="unitReprstPreservePo.contactFax" :rules="[{required: true, message: '请输入传真', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactFax" placeholder="请输入传真">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="手机" prop="unitReprstPreservePo.contactPhone" :rules="[{required: true, message: '请输入手机', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactPhone" placeholder="请输入手机">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="办公电话" prop="unitReprstPreservePo.contactTelephone" :rules="[{required: true, message: '请输入办公电话', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactTelephone" placeholder="请输入办公电话">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="微信" prop="unitReprstPreservePo.contactWechat" :rules="[{required: true, message: '请输入微信', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactWechat" placeholder="请输入微信">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="Email" prop="unitReprstPreservePo.contactEmail" :rules="[{required: true, message: '请输入Email', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactEmail" placeholder="请输入Email">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-row :gutter="20">
                <el-col :span="16">
                  <el-form-item label="通讯地址" prop="unitReprstPreservePo.contactMailingAddress" :rules="[{required: true, message: '请输入通讯地址', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactMailingAddress" placeholder="请输入通讯地址">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="邮政编码" prop="unitReprstPreservePo.contactZipCode" :rules="[{required: true, message: '请输入邮政编码', trigger: 'blur'}]">
                    <el-input v-model="addForm1.unitReprstPreservePo.contactZipCode" placeholder="请输入邮政编码">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--  -->
              <el-form-item label="注" required>
                请填写完成后<span class="text-btn" @click="download('addForm2')">点击此处</span>下载pdf文件后签字并加盖公章，在第三部分资料上传进行提交
                <div>纸质材料原件（一份）邮寄至：北京市西城区广安门内大街315号信息大厦B座6层605室，马倩，17710420316，010-63691467</div>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="3">
            <el-form :model="addFormFile" ref="addForm3" label-position="top">
              <el-form-item label="单位简介" prop="introductory" :rules="[{required: true, message: '请输入单位简介', trigger: 'blur'}]">
                <el-input v-model="addFormFile.introductory" type="textarea" placeholder="请输入单位简介" maxlength="500" show-word-limit>
                </el-input>
              </el-form-item>
              <el-form-item prop="unitPdfFile" label="企业信息表（加盖公章，限10MB 以内的 PDF 文件）：" :rules="[{required: true, message: '请上传文件', trigger: 'change'}]">
                <el-checkbox-group v-model="addFormFile.unitPdfFile" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig1" @UploadFileFun="UploadFileFun($event, 'unitPdfFile')" />
              </el-form-item>
              <!--  -->
              <el-form-item prop="businessLicenseFile" label="单位代表和联系人登记表（加盖公章，限10MB 以内的 PDF 文件）：" :rules="[{required: true, message: '请上传文件', trigger: 'change'}]">
                <el-checkbox-group v-model="addFormFile.businessLicenseFile" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig2" @UploadFileFun="UploadFileFun($event, 'businessLicenseFile')" />
              </el-form-item>
              <!--  -->
              <el-form-item prop="unitReprstFile" label="企业营业执照或法人登记证书（ 加盖公章，限10MB 以内的 PDF 文件 );" :rules="[{required: true, message: '请上传文件', trigger: 'change'}]">
                <el-checkbox-group v-model="addFormFile.unitReprstFile" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadImageConfig" @UploadFileFun="UploadFileFun($event, 'unitReprstFile')" />
                <!-- <UploadImage :config="uploadImageConfig" @UploadImageFun="UploadFileFun($event, 'unitReprstFile')"></UploadImage> -->
              </el-form-item>
              <!--  -->
              <el-form-item label="其他附件（加盖公章，限10MB以内的PDF文件）：" >
                <el-checkbox-group v-model="addFormFile.otherFiles" v-show="false"></el-checkbox-group>
                <UploadFile :config="uploadFileConfig3" @UploadFileFun="UploadFileFun($event, 'otherFiles')" />
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <div style="text-align: center;">
          <el-button v-if="activeName > 1" @click="prev">上一步</el-button>
          <el-button v-if="activeName < 3" @click="next">下一步</el-button>
          <el-button type="primary" :loading="saveLoading" @click="handleSave('save')">保存</el-button>
          <el-button v-if="activeName == 3" type="primary" :loading="submitLoading" @click="handleSave('submit')">提交</el-button>
        </div>
      </div>
      <div v-else class="form-box">
        <div v-if="state !== -1">
          <div class="state-title">
            <i v-if="state === 1" class="el-icon-pie-chart"></i>
            <span v-if="state === 1">您已提交入会申请，请等待审核</span>
            <span v-if="state === 2">您的入会申请已经通过</span>
            <span v-if="state === 3">管理员拒绝了您的入会申请，原因：{{ remarks }}</span>
            <span v-if="state === 4">您的入会申请被管理员退回，退回原因：{{ remarks }}</span>
            <div style="margin-top: 40px;">
              <el-button v-if="state === 4" type="primary" @click="again(1)">返回修改</el-button>
              <!-- <el-button type="primary" @click="again(1)">返回修改</el-button> -->
              <el-button v-if="state === 3" type="primary" @click="again(2)">重新填写</el-button>
            </div>
          </div>
          <div class="record-box">
            <div>申请记录</div>
            <div class="record-list">
              <el-table border :data="recordList" highlight-current-row header-row-class-name="table-header-class"
                :header-cell-style="{ 'background-color': '#F7F8FA' }" style="width: 100%; min-height: 200px">
                <el-table-column prop="nickName" label="申请人" min-width="30%">
                </el-table-column>
                <el-table-column prop="createDate" label="申请时间" align="center" min-width="40%">
                </el-table-column>
                <el-table-column prop="state " label="状态" align="center" min-width="30%">
                  <template slot-scope="scope">
                    <span v-if="scope.row.state === 1">待确认</span>
                    <span v-if="scope.row.state === 2">已同意</span>
                    <span v-if="scope.row.state === 3">已拒绝</span>
                    <span v-if="scope.row.state === 4">退回修改</span>
                    <span v-if="scope.row.state === 5">再次确认</span>
                    <el-tooltip v-if="
                      (scope.row.state === 3 || scope.row.state === 4) &&
                      scope.row.remarks
                    " :content="scope.row.remarks" placement="top">
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="record-box" style="margin-top: 20px; line-height: 25px;">
            纸质材料原件（一份）邮寄至：<br />
            地址：北京市西城区广安门内大街315号信息大厦B座6层605室；<br />
            联系人：马倩；<br />
            联系电话：17710420316，010-63691467；
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog title="工作简历" :visible.sync="jobForm.dialogVisible" @close="closeJobForm" :modal-append-to-body='false'>
      <el-form ref="jobForm" :model="jobForm" label-width="100px" >
        <el-form-item label="起止年月" prop="time" :rules="[{required: true, message: '请输入起止年月', trigger: 'blur'}]">
          <el-input v-model="jobForm.time" placeholder="请输入起止年月"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="unit" :rules="[{required: true, message: '请输入单位名称', trigger: 'blur'}]">
          <el-input v-model="jobForm.unit" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="position" :rules="[{required: true, message: '请输入职务', trigger: 'blur'}]">
          <el-input v-model="jobForm.position" placeholder="请输入职务"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="jobForm.dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="jobFormSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="人物" :visible.sync="postForm.dialogVisible" @close="closePostForm" :modal-append-to-body='false'>
      <el-form ref="postForm" :model="postForm" label-width="100px" >
        <el-form-item label="姓名" prop="name" :rules="[{required: true, message: '请输入姓名'}]">
          <el-input v-model="postForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="现职位" prop="nowPosition" :rules="[{required: true, message: '请输入现职位'}]">
          <el-input v-model="postForm.nowPosition" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="原职位" prop="pastPosition" :rules="[{required: true, message: '请输入原职位'}]">
          <el-input v-model="postForm.pastPosition" placeholder="请输入职务"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="postForm.dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="postFormSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import Header from "@/components/header/Header.vue"
import UploadFile from "@c/upload/UploadFile.vue";
import UploadImage from "@c/upload/UploadImage.vue";
import Merge from "@c/merge/Merge.vue"
import { IndexStore } from "@/store/module/index";
import { regionDataPlus, odeToText, CodeToText, provinceAndCityData, regionData } from "element-china-area-data"
import {
  GetUnitState, //获取单位入会申请状态
  GetUnitRecordList, // 获取单位会申请记录列表详情
  GetInstitutionAllList, //获取分支信息
  UnitApplyAdd, // 单位入会表单提交
  UnitApplyDetail // 获取个人入会申请详情
} from "@/http/api/InitiationApi"
import { FileDownload2 } from "@/http/api/FileApi"

export default {
  components: {
    Header,
    Merge,
    UploadFile,
    UploadImage
  },
  data() {
    return {
      recordList: [],
      formShow: false, // 表单是否显示
      state: -1, // 状态 1.待确认 2.已同意 3.已拒绝 4.退回修改 5.再次确认 6.草稿 99.没有状态第一次进来
      uploadFileConfig1: {
        limit: 1,
        callback: []
      },
      uploadFileConfig2: {
        limit: 1,
        callback: []
      },
      uploadFileConfig3: {
        limit: 3,
        callback: []
      },
      uploadImageConfig: {
        limit: 1,
        callback: ""
      },
      postForm: {
        name: '',
        nowPosition: '',
        pastPosition: '',
        type: '',
        index: '',
        dialogVisible: false,
      },
      jobForm: {
        index: '',
        dialogVisible: false,
        position: '',
        memberId: '',
        time: '',
        unit: '',
      },                       // 工作简历表
      embranchmentListst: [],
      embranchmentListqt: [], // 分支机构
      regionData: regionData, // 省市区
      unitNature: 1,
      industryNatureName: '',
      unitNatureName: '',
      saveLoading: false,
      submitLoading: false,
      pageLoading: false,
      activeName: '1',
      userInfo: {},
      addFormFile: {
        introductory: '',
        unitPdfFile: '',
        unitReprstFile: '',
        businessLicenseFile: '',
        otherFiles: '',
      },
      // 
      addForm1: {
        // 第一部分其他类型数据
        unitPreservePo: {
          unitEnterprisePo: {
            unitNature: '',
            industryNature: '',            // 行业性质
            natureCounte: '', //其他行业性质
            funderUnit: '',                // 出资筹建单位
            businessScope: '',             // 业务经营范围
            registeredFunds: '',           // 注册资金
            registeredTime: '',            // 注册时间
            annualRevenue: '',             // 年营业收入
            totalFunds: '',                // 总资产
            stuffNumber: '',               // 职工人数
            assetRetio: '',                // 资产负债概率
            personnelProportion: '',       // 具有大专及以上学历的中高层管理人员比例
            communicateAddress: '',        // 通讯地址
            zipCode: '',                   // 邮政编码
            legalRepresentative: '',       // 法人代表
            nationality: '',               // 国籍
            position: '',                  // 职务
            partySecretary: '',            // 党组书记
            chairman: '',                  // 董事长
            generalManager: '',            // 总经理
            deputyGeneralManager: '',      // deputyGeneralManager
            url: '',                       // 网址
            wechatOffcialNo: '',           // 微信公众号
            weibo: '',                     // 微博
            tikTok: '',                    // 抖音
            unitIntroduction: '',          // 单位简介
            //  
          },
          id: '',
          unitAddress: [],              // 单位地址
          provinceCode: '',
          cityCode: '',
          countyCode: '',
          specificAddress: '',          // 单位地址具体地址
          embranchmentId: '',           // 分支机构id
          embranchmentIds: [],          // 分支机构id
          memberInstitutions: [],
          fromRemarks: '',
          preparer: '',                 // 填表人
          contactPhone: '',             // 联系电话
        },
        // 第一部分社团
        unitPreservePos: {
          unitCommunityPo: {
            unitCompetent: '',              // 单位主管
            unitEstablishTime: '',          // 成立时间
            periodNumber: '',               // 届数
            lastChangePeriodTime: '',       // 上次换届时间
            unitOfficeAddress: '',          // 单位办公地址
            unitZipCode: '',                // 单位邮政编码
            unitPhone: '',                  // 单位电话
            unitFax: '',                    // 单位传真
            memberUnitNumber: '',           // 单位会员人数
            memberPersonNumber: '',         // 个人会员人数
            institutionNumber: '',          // 分支机构人数
            councilNumber: '',              // 理事人数
            routineCouncilNumber: '',       // 常务理事人数
            fullTimeNumber: '',             // 专职工作人数
            contacts: '',                   // 常用联系人
            contactsPhone: '',               // 常用联系人电话
            contactsFax: '',                // 常用联系人传真
            contactsAddress: '',            // 常用联系人地址
            contactsZipCode: '',            // 常用联系人邮政编码
            // 1.会长 2.副会长 3.秘书长 4.副秘书长
            memberForCommPositionPos: []
          },
          post1: { name: '', nowPosition: '', pastPosition: '', type: 1 },
          post2: [],
          post3: { name: '', nowPosition: '', pastPosition: '', type: 3 },
          post4: [],
          unitAddress: [],              // 单位地址
          provinceCode: '',
          cityCode: '',
          countyCode: '',
          specificAddress: '',          // 单位地址具体地址
          embranchmentId: '',           // 分支机构id
          embranchmentIds: [],          // 分支机构id
          memberInstitutions: [],
          fromRemarks: '',
          preparer: '',                 // 填表人
          contactPhone: '',             // 联系电话
        },
        // MemberUnitCommunityPo 
        // 第二部分
        unitReprstPreservePo: {
          name: '',            // 姓名
          sex: '',             // 性别 1.男 2.女
          birthday: '',        // 出生年月日
          national: '',        // 民族
          national: '',     // 籍贯
          politicsStatus: '',  // 政治面貌
          position: '',        // 职务
          title: '',           // 职称
          education: '',       // 学历
          degree: '',          // 学位
          phone: '',           // 手机
          telephone: '',       // 办公电话
          wechat: '',          // 微信
          email: '',           // Email
          fax: '',             // 传真
          mailingAddress: '',  // 通讯地址
          zipCode: '',         // 邮政编码
          memberForResumes: [
            // { memberId: '', position: '', time: '', unit: ''},
          // memberId: '', 会员申请id ,
          // position: '', 职务 ,
          // time: '', 起止时间 ,
          // unit: '', 单位
          ],
          // 
          contactName: '',     // 联系人姓名
          contactPosition: '', // 职务
          contactFax: '',      // 传真
          contactPhone: '',    // 手机
          contactTelephone: '',// 办公电话
          contactWechat: '',   // 微信
          contactEmail: '',    // Email
          contactMailingAddress: '',      // 通讯地址
          contactZipCode: '',            // 邮政编码

        },
      },
      addForm1Rules: {
        unitNature: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      // 
      addFormA: {},
      addFormARules: {}
    }
  },
  created () {
    this.getInstitutionList()
    this.getpo1Fun()
    this.getState()

  },
  methods: {
    common (status) {
      const parmas = {
        unitReprstPreservePo: this.addForm1.unitReprstPreservePo,
        unitPreservePo: {}
      }
      parmas.unitReprstPreservePo.unitReprstFile = this.addFormFile.unitReprstFile
      // 第一部分
      let arr = []
      let arrs = []
      if (this.unitNature != 5) {
        parmas.unitPreservePo = this.addForm1.unitPreservePo
        if (this.addForm1.unitPreservePo.embranchmentId) {
          arr.push({ type: 1, institutionsId: this.addForm1.unitPreservePo.embranchmentId })
        }
        if (this.addForm1.unitPreservePo.embranchmentIds && this.addForm1.unitPreservePo.embranchmentIds.length > 0) {
          this.addForm1.unitPreservePo.embranchmentIds.forEach(item => {
            arrs.push({ type: 2, institutionsId: item })
          })
        }
        parmas.unitPreservePo.memberInstitutions = [...arr, ...arrs]
        if (parmas.unitPreservePo.unitEnterprisePo.industryNature != 7) {
          parmas.unitPreservePo.unitEnterprisePo.natureCounte = ''
        }
      } else {
        parmas.unitPreservePo = this.addForm1.unitPreservePos
        if (this.addForm1.unitPreservePos.embranchmentId) {
          arr.push({ type: 1, institutionsId: this.addForm1.unitPreservePos.embranchmentId })
        }
        if (this.addForm1.unitPreservePos.embranchmentIds && this.addForm1.unitPreservePos.embranchmentIds.length > 0) {
          this.addForm1.unitPreservePos.embranchmentIds.forEach(item => {
            arrs.push({ type: 2, institutionsId: item })
          })
        }
        parmas.unitPreservePo.memberInstitutions = [...arr, ...arrs]
        let arrss = []
        arrss.push(this.addForm1.unitPreservePos.post1, this.addForm1.unitPreservePos.post3)
        arrss = [...arrss, ...this.addForm1.unitPreservePos.post2, ...this.addForm1.unitPreservePos.post4]
        parmas.unitPreservePo.unitCommunityPo.memberForCommPositionPos = arrss
      }

      parmas.unitPreservePo.unitNature = this.unitNature
      parmas.unitPreservePo.isDraft = status
      parmas.unitPreservePo.introductory = this.addFormFile.introductory
      parmas.unitPreservePo.businessLicenseFile = this.addFormFile.businessLicenseFile
      parmas.unitPreservePo.unitPdfFile = this.addFormFile.unitPdfFile
      parmas.unitPreservePo.otherFiles = this.addFormFile.otherFiles
      // 
      UnitApplyAdd(parmas).then(res => {
        if (res.code == '000') {
          if (status == 1) {
            this.$message.success('保存成功')
            this.getState()
          } else {
            this.getState()
            this.$message.success("提交成功")
          }
          
        } 
      })

    },
    // 保存 提交
    handleSave(type) {
      this.$confirm( `确定要${type === 'save' ? "保存" : "提交"}申请表吗？`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (type == 'save') {
          this.common(1)
        } else {
          let flag1 = false
          let flag2 = false
          let flag3 = false
          if (this.unitNature != 5) {
            this.$refs.addForm1.validate(valid => { if (valid) { flag1 = true} })
          } else {
            this.$refs.addFormA.validate(valid => { if (valid) { flag1 = true} })
          }
          
          this.$refs.addForm2.validate(valid => { if (valid) { flag2 = true} })
          this.$refs.addForm3.validate(valid => { if (valid) { flag3 = true} })
          console.log(flag1, flag2, flag3)
          if (flag1 && flag2 && flag3) {
            this.common(2)
          } else {
            this.$message.error('您还有必填项没有填写，请填写完成后再提交')
          }
        }
      })
      
    },
    // 回显
    getDetail(id) {
      this.pageLoading = true;
      UnitApplyDetail({ id }).then(res => {
        this.pageLoading = false;
        console.log(res.result)
        if (res.code === "000") {
          const data = res.result
          const memberUnitVo = data.memberUnitVo
          const unitReprstVo = data.unitReprstVo
          this.unitNature = memberUnitVo.unitNature
          if (this.unitNature != 5) {
            this.addForm1.unitPreservePo.unitEnterprisePo.unitNature = memberUnitVo.unitNature
            this.addForm1.unitPreservePo.unitEnterprisePo.industryNature = memberUnitVo.unitEnterpriseVo.industryNature || ''
            this.addForm1.unitPreservePo.unitEnterprisePo.natureCounte = memberUnitVo.unitEnterpriseVo.natureCounte
            this.addForm1.unitPreservePo.unitEnterprisePo.funderUnit = memberUnitVo.unitEnterpriseVo.funderUnit
            this.addForm1.unitPreservePo.unitEnterprisePo.businessScope = memberUnitVo.unitEnterpriseVo.businessScope
            this.addForm1.unitPreservePo.unitEnterprisePo.registeredFunds = memberUnitVo.unitEnterpriseVo.registeredFundsNum
            this.addForm1.unitPreservePo.unitEnterprisePo.registeredTime = memberUnitVo.unitEnterpriseVo.registeredTime || ''
            this.addForm1.unitPreservePo.unitEnterprisePo.annualRevenue = memberUnitVo.unitEnterpriseVo.annualRevenueNum
            this.addForm1.unitPreservePo.unitEnterprisePo.totalFunds = memberUnitVo.unitEnterpriseVo.totalFundsNum
            this.addForm1.unitPreservePo.unitEnterprisePo.stuffNumber = memberUnitVo.unitEnterpriseVo.stuffNumber
            this.addForm1.unitPreservePo.unitEnterprisePo.assetRetio = memberUnitVo.unitEnterpriseVo.assetRetio
            this.addForm1.unitPreservePo.unitEnterprisePo.personnelProportion = memberUnitVo.unitEnterpriseVo.personnelProportion
            this.addForm1.unitPreservePo.unitEnterprisePo.communicateAddress = memberUnitVo.unitEnterpriseVo.communicateAddress
            this.addForm1.unitPreservePo.unitEnterprisePo.personnelProportion = memberUnitVo.unitEnterpriseVo.personnelProportion
            this.addForm1.unitPreservePo.unitEnterprisePo.zipCode = memberUnitVo.unitEnterpriseVo.zipCode
            this.addForm1.unitPreservePo.unitEnterprisePo.legalRepresentative = memberUnitVo.unitEnterpriseVo.legalRepresentative
            this.addForm1.unitPreservePo.unitEnterprisePo.nationality = memberUnitVo.unitEnterpriseVo.nationality
            this.addForm1.unitPreservePo.unitEnterprisePo.position = memberUnitVo.unitEnterpriseVo.position
            this.addForm1.unitPreservePo.unitEnterprisePo.partySecretary = memberUnitVo.unitEnterpriseVo.partySecretary
            this.addForm1.unitPreservePo.unitEnterprisePo.chairman = memberUnitVo.unitEnterpriseVo.chairman
            this.addForm1.unitPreservePo.unitEnterprisePo.generalManager = memberUnitVo.unitEnterpriseVo.generalManager
            this.addForm1.unitPreservePo.unitEnterprisePo.deputyGeneralManager = memberUnitVo.unitEnterpriseVo.deputyGeneralManager
            this.addForm1.unitPreservePo.unitEnterprisePo.url = memberUnitVo.unitEnterpriseVo.url
            this.addForm1.unitPreservePo.unitEnterprisePo.wechatOffcialNo = memberUnitVo.unitEnterpriseVo.wechatOffcialNo
            this.addForm1.unitPreservePo.unitEnterprisePo.weibo = memberUnitVo.unitEnterpriseVo.weibo
            this.addForm1.unitPreservePo.unitEnterprisePo.tikTok = memberUnitVo.unitEnterpriseVo.tikTok
            this.addForm1.unitPreservePo.unitEnterprisePo.unitIntroduction = memberUnitVo.unitEnterpriseVo.unitIntroduction
            this.addForm1.unitPreservePo.specificAddress = memberUnitVo.specificAddress
            this.addForm1.unitPreservePo.id = memberUnitVo.id
            this.addForm1.unitPreservePo.fromRemarks = memberUnitVo.fromRemarks
            this.addForm1.unitPreservePo.preparer = memberUnitVo.preparer
            this.addForm1.unitPreservePo.contactPhone = memberUnitVo.contactPhone
            const arr = []
            let str = ''
            if (memberUnitVo.memberInstitutions && memberUnitVo.memberInstitutions.length > 0) {
              memberUnitVo.memberInstitutions.forEach(item => {
                if (item.type == 1) {
                  str = item.institutionsId
                }
                if (item.type == 2) {
                  arr.push(item.institutionsId)
                }
              })
              this.embranchmentListqt.forEach(item => {
                if (str == item.institutionsId) {
                  item.disabled = true
                }
              })
              this.addForm1.unitPreservePo.embranchmentId = str
              this.addForm1.unitPreservePo.embranchmentIds = arr
            }
            if (memberUnitVo.codes) {
              this.addForm1.unitPreservePo.unitAddress = memberUnitVo.codes.split(',')
              this.addForm1.unitPreservePo.provinceCode = memberUnitVo.codes.split(',')[0]
              this.addForm1.unitPreservePo.cityCode = memberUnitVo.codes.split(',')[1]
              this.addForm1.unitPreservePo.countyCode = memberUnitVo.codes.split(',')[2]
            }
          } else {
            this.addForm1.unitPreservePos.unitCommunityPo.unitCompetent = memberUnitVo.unitCommunityVo.unitCompetent
            this.addForm1.unitPreservePos.unitCommunityPo.unitEstablishTime = memberUnitVo.unitCommunityVo.unitEstablishTime || ''
            this.addForm1.unitPreservePos.unitCommunityPo.periodNumber = memberUnitVo.unitCommunityVo.periodNumber
            this.addForm1.unitPreservePos.unitCommunityPo.lastChangePeriodTime = memberUnitVo.unitCommunityVo.lastChangePeriodTime || ''
            this.addForm1.unitPreservePos.unitCommunityPo.unitOfficeAddress = memberUnitVo.unitCommunityVo.unitOfficeAddress
            this.addForm1.unitPreservePos.unitCommunityPo.unitZipCode = memberUnitVo.unitCommunityVo.unitZipCode
            this.addForm1.unitPreservePos.unitCommunityPo.unitPhone = memberUnitVo.unitCommunityVo.unitPhone
            this.addForm1.unitPreservePos.unitCommunityPo.unitFax = memberUnitVo.unitCommunityVo.unitFax
            this.addForm1.unitPreservePos.unitCommunityPo.memberUnitNumber = memberUnitVo.unitCommunityVo.memberUnitNumber || ''
            this.addForm1.unitPreservePos.unitCommunityPo.memberPersonNumber = memberUnitVo.unitCommunityVo.memberPersonNumber || ''
            this.addForm1.unitPreservePos.unitCommunityPo.institutionNumber = memberUnitVo.unitCommunityVo.institutionNumber || ''
            this.addForm1.unitPreservePos.unitCommunityPo.councilNumber = memberUnitVo.unitCommunityVo.councilNumber || ''
            this.addForm1.unitPreservePos.unitCommunityPo.routineCouncilNumber = memberUnitVo.unitCommunityVo.routineCouncilNumber || ''
            this.addForm1.unitPreservePos.unitCommunityPo.fullTimeNumber = memberUnitVo.unitCommunityVo.fullTimeNumber || ''
            this.addForm1.unitPreservePos.unitCommunityPo.contacts = memberUnitVo.unitCommunityVo.contacts
            this.addForm1.unitPreservePos.unitCommunityPo.contactsPhone = memberUnitVo.unitCommunityVo.contactsPhone
            this.addForm1.unitPreservePos.unitCommunityPo.contactsFax = memberUnitVo.unitCommunityVo.contactsFax
            this.addForm1.unitPreservePos.unitCommunityPo.contactsAddress = memberUnitVo.unitCommunityVo.contactsAddress
            this.addForm1.unitPreservePos.unitCommunityPo.contactsZipCode = memberUnitVo.unitCommunityVo.contactsZipCode

            this.addForm1.unitPreservePos.specificAddress = memberUnitVo.specificAddress
            this.addForm1.unitPreservePos.id = memberUnitVo.id
            this.addForm1.unitPreservePos.fromRemarks = memberUnitVo.fromRemarks
            this.addForm1.unitPreservePos.preparer = memberUnitVo.preparer
            this.addForm1.unitPreservePos.contactPhone = memberUnitVo.contactPhone
            if (memberUnitVo.unitCommunityVo.memberForCommPositionVos && memberUnitVo.unitCommunityVo.memberForCommPositionVos.length > 0) {
              memberUnitVo.unitCommunityVo.memberForCommPositionVos.forEach(item => {
                if (item.type == 1) {
                  this.addForm1.unitPreservePos.post1 = item
                }
                if (item.type == 2) {
                  this.addForm1.unitPreservePos.post2.push(item)
                }
                if (item.type == 3) {
                  this.addForm1.unitPreservePos.post3 = item
                }
                if (item.type == 4) {
                  this.addForm1.unitPreservePos.post4.push(item)
                }
              })
            }
            const arr = []
            let str = ''
            if (memberUnitVo.memberInstitutions && memberUnitVo.memberInstitutions.length > 0) {
              memberUnitVo.memberInstitutions.forEach(item => {
                if (item.type == 1) {
                  str = item.institutionsId
                }
                if (item.type == 2) {
                  arr.push(item.institutionsId)
                }
              })
              this.embranchmentListst.forEach(item => {
                if (str == item.institutionsId) {
                  item.disabled = true
                }
              })
              this.addForm1.unitPreservePos.embranchmentId = str
              this.addForm1.unitPreservePos.embranchmentIds = arr
            }
            if (memberUnitVo.codes) {
              this.addForm1.unitPreservePos.unitAddress = memberUnitVo.codes.split(',')
              this.addForm1.unitPreservePos.provinceCode = memberUnitVo.codes.split(',')[0]
              this.addForm1.unitPreservePos.cityCode = memberUnitVo.codes.split(',')[1]
              this.addForm1.unitPreservePos.countyCode = memberUnitVo.codes.split(',')[2]
            }

          }
          // 第二部分
          unitReprstVo.sex = unitReprstVo.sex || ''
          unitReprstVo.birthday = unitReprstVo.birthday || ''
          this.addForm1.unitReprstPreservePo = unitReprstVo
          // 第三部分
          this.addFormFile.introductory = memberUnitVo.introductory || ''
          if (memberUnitVo.unitPdfs && memberUnitVo.unitPdfs.length > 0) {
            this.uploadFileConfig1.callback = memberUnitVo.unitPdfs
          }
          if (memberUnitVo.businessLicenses && memberUnitVo.businessLicenses.length > 0) {
            this.uploadFileConfig2.callback = memberUnitVo.businessLicenses
          }
          if (memberUnitVo.others && memberUnitVo.others.length > 0) {
            this.uploadFileConfig3.callback = memberUnitVo.others
          }
          if (unitReprstVo.unitReprsts && unitReprstVo.unitReprsts.length > 0) {
            this.uploadImageConfig.callback = unitReprstVo.unitReprsts
          }
          
        }
      })
    },
    // 下载
    download(type) {
      console.log('导出', type)
      switch (this.unitNature) {
        case 1:
          this.unitNatureName = '国企'
          break
        case 2:
          this.unitNatureName = '事业'
          break
        case 3:
          this.unitNatureName = '合资'
          break
        case 4:
          this.unitNatureName = '民营'
          break
        case 5:
          this.unitNatureName = '社团'
          break
        case 6:
          this.unitNatureName = '其他'
          break
      }
      switch (Number(this.addForm1.unitPreservePo.unitEnterprisePo.industryNature)) {
        case 1:
          this.industryNatureName = '桥梁工程'
          break
        case 2:
          this.industryNatureName = '隧道工程'
          break
        case 3:
          this.industryNatureName = '基础设施'
          break
        case 4:
          this.industryNatureName = '装备技术'
          break
        case 5:
          this.industryNatureName = '运输综合'
          break
        case 6:
          this.industryNatureName = '物流'
          break
        case 7:
          this.industryNatureName = '其他'
          break
      }
      console.log(this.industryNatureName)
      let str = ''
      if (type == 'addForm1') {
        const unitAddress = this.addForm1.unitPreservePo.unitAddress
        if (unitAddress.length > 0) {
          str = CodeToText[unitAddress[0]] + CodeToText[unitAddress[1]] + CodeToText[unitAddress[2]]
        }
        const parmas = JSON.parse(JSON.stringify(this.addForm1.unitPreservePo.unitEnterprisePo))
        parmas.fromRemarks = this.addForm1.unitPreservePo.fromRemarks
        parmas.specificAddress = this.addForm1.unitPreservePo.specificAddress
        parmas.preparer = this.addForm1.unitPreservePo.preparer
        parmas.contactPhone = this.addForm1.unitPreservePo.contactPhone
        parmas.unitAddress = str + this.addForm1.unitPreservePo.specificAddress
        parmas.unitNature = this.unitNatureName
        parmas.industryNature = this.industryNatureName
        parmas.natureCounte = this.addForm1.unitPreservePo.unitEnterprisePo.industryNature == 7 ? this.addForm1.unitPreservePo.unitEnterprisePo.natureCounte: null
        parmas.loginName = this.userInfo.loginName
        parmas.nikeName = this.userInfo.nikeName
        parmas.focusOrgNames = ''
        parmas.joinOrgName = ''
        if (this.addForm1.unitPreservePo.embranchmentId) {
          this.embranchmentListqt.forEach(item => {
            if (item.institutionsId == this.addForm1.unitPreservePo.embranchmentId) {
              parmas.joinOrgName = item.nickName
            }
          })
        }
        if (this.addForm1.unitPreservePo.embranchmentIds.length > 0) {
          const arr = []
          this.addForm1.unitPreservePo.embranchmentIds.forEach(item => {
            this.embranchmentListqt.forEach(items => {
              if (items.institutionsId == item) {
                arr.push(items.nickName)
              }
            })
          })
          parmas.focusOrgNames = arr.join()
        }
        FileDownload2("/member/web/pdf/member/unit/enterprise", parmas)
      } else if (type == 'addForm2') {
        const params2 = JSON.parse(JSON.stringify(this.addForm1.unitReprstPreservePo))
        if (params2.sex) {
          params2.sex = params2.sex == 1 ? '男': '女'
        }
        FileDownload2("/member/web/pdf/unit/reprst", params2)
      } else if (type == 'addFormA') {

        const unitAddress = this.addForm1.unitPreservePos.unitAddress
        if (unitAddress.length > 0) {
          str = CodeToText[unitAddress[0]] + CodeToText[unitAddress[1]] + CodeToText[unitAddress[2]]
        }
        const parmasA = JSON.parse(JSON.stringify(this.addForm1.unitPreservePos.unitCommunityPo))
        parmasA.fromRemarks = this.addForm1.unitPreservePos.fromRemarks

        parmasA.unitAddress = str + this.addForm1.unitPreservePos.specificAddress
        // let arrs = []
        // arrs.push(this.addForm1.unitPreservePos.post1, this.addForm1.unitPreservePos.post3)
        // arrs = [...arrs, ...this.addForm1.unitPreservePos.post2, ...this.addForm1.unitPreservePos.post4]
        parmasA.presidentPos = [this.addForm1.unitPreservePos.post1]
        parmasA.vicePresidentPos = this.addForm1.unitPreservePos.post2
        parmasA.secretaryPos = [this.addForm1.unitPreservePos.post3]
        parmasA.vicSecretaryPos = this.addForm1.unitPreservePos.post4
        parmasA.specificAddress = this.addForm1.unitPreservePos.specificAddress
        parmasA.preparer = this.addForm1.unitPreservePos.preparer
        parmasA.contactPhone = this.addForm1.unitPreservePos.contactPhone
        parmasA.unitAddress = str + this.addForm1.unitPreservePos.specificAddress
        parmasA.unitNature = this.unitNatureName
        // parmasA.industryNature = this.industryNatureName
        parmasA.loginName = this.userInfo.loginName
        parmasA.nikeName = this.userInfo.nikeName
        parmasA.focusOrgNames = ''
        parmasA.joinOrgName = ''
        if (this.addForm1.unitPreservePos.embranchmentId) {
          this.embranchmentListst.forEach(item => {
            if (item.institutionsId == this.addForm1.unitPreservePos.embranchmentId) {
              parmasA.joinOrgName = item.nickName
            }
          })
        }
        if (this.addForm1.unitPreservePos.embranchmentIds.length > 0) {
          const arr = []
          this.addForm1.unitPreservePos.embranchmentIds.forEach(item => {
            this.embranchmentListqt.forEach(items => {
              if (items.institutionsId == item) {
                arr.push(items.nickName)
              }
            })
          })
          parmasA.focusOrgNames = arr.join()
        }
        FileDownload2("/member/web/pdf/member/unit/community", parmasA)

      }
    },
    // 获取申请表状态
    getState() {
      this.pageLoading = true;
      GetUnitState().then((res) => {
        this.pageLoading = false;
        if (res.code === "000") {
          if (res.result) {
            this.id = res.result.id
            this.remarks = res.result.remarks
            this.state = res.result.state
            if (res.result.state === 6) {
              this.formShow = true;
              this.getDetail(res.result.id);
            } else {
              this.formShow = false;
              this.getRecordList();
            }
          } else {
            this.state = 99;
          }
          if (res.result && res.result.state === 3) {
            this.id = ''
          }
        }
      });
    },
    // 获取申请记录历史表
    getRecordList() {
      GetUnitRecordList({
        pageNo: 1,
        pageSize: 10000,
      }).then((res) => {
        if (res.code === "000") {
          this.recordList = res.result.records;
        }
      });
    },
    // 获取所有分支机构
    getInstitutionList() {
      GetInstitutionAllList().then((res) => {
        if (res.code === "000") {
          this.embranchmentListst = res.result
          this.embranchmentListqt = res.result
        }
      })
    },
    // 获取用户信息
    getpo1Fun() {
      if (!IndexStore.userInfo) {
        setTimeout(() => {
          this.getpo1Fun();
        }, 1000);
      } else {
        this.userInfo = IndexStore.userInfo
      }
    },
    // 返回首页
    goBack() {
      if (this.userInfo.userRole == 1) {
        this.$router.push({ path: '/institution/member/unit' })
      } else {
        this.$router.push({ path: '/serve' })
      }
    },
    industryNatureChange(val) {
      switch (val) {
        case 1:
          this.industryNatureName = '桥梁工程'
          break
        case 2:
          this.industryNatureName = '隧道工程'
          break
        case 3:
          this.industryNatureName = '基础设施'
          break
        case 4:
          this.industryNatureName = '装备技术'
          break
        case 5:
          this.industryNatureName = '运输综合'
          break
        case 6:
          this.industryNatureName = '物流'
          break
        case 7:
          this.industryNatureName = '其他'
          break
      }
    },
    unitNatureChange(val) {
      switch (val) {
        case 1:
          this.unitNatureName = '国企'
          break
        case 2:
          this.unitNatureName = '事业'
          break
        case 3:
          this.unitNatureName = '合资'
          break
        case 4:
          this.unitNatureName = '民营'
          break
        case 5:
          this.unitNatureName = '社团'
          break
        case 6:
          this.unitNatureName = '其他'
          break
      }
    },
    // 地址
    unitAddressChange(val, type) {
      if (type == 'addForm1') {
        this.addForm1.unitPreservePo.provinceCode = val[0] || ''
        this.addForm1.unitPreservePo.cityCode = val[1] || ''
        this.addForm1.unitPreservePo.countyCode = val[2] || ''
      } else if (type == 'addFormA') {
        this.addForm1.unitPreservePos.provinceCode = val[0] || ''
        this.addForm1.unitPreservePos.cityCode = val[1] || ''
        this.addForm1.unitPreservePos.countyCode = val[2] || ''
      }
    },
    // 分支机构选择
    embranchmentIdChange(val, type) {
      if (type == 'addForm1') {
        this.addForm1.unitPreservePo.embranchmentIds = []
        this.embranchmentListqt.forEach(item => {
          item.disabled = false
          if (item.institutionsId == val) {
            item.disabled = true
          }
        })
      } else if (type == 'addFormA') {
        this.addForm1.unitPreservePos.embranchmentIds = []
        this.embranchmentListst.forEach(item => {
          item.disabled = false
          if (item.institutionsId == val) {
            item.disabled = true
          }
        })
      }
    },
    // 个人工作简历
    jobFormSubmit() {
      this.$refs.jobForm.validate(valid => {
        if (!valid) return false
        if (this.jobForm.index === '') {
          this.addForm1.unitReprstPreservePo.memberForResumes.push({
            time: this.jobForm.time,
            unit: this.jobForm.unit,
            position: this.jobForm.position
          })
        } else {
          this.addForm1.unitReprstPreservePo.memberForResumes[this.jobForm.index].time = this.jobForm.time
          this.addForm1.unitReprstPreservePo.memberForResumes[this.jobForm.index].unit = this.jobForm.unit
          this.addForm1.unitReprstPreservePo.memberForResumes[this.jobForm.index].position = this.jobForm.position
        }
        this.jobForm.dialogVisible = false
        this.jobForm.index = ''
      })

    },
    memberForResumesEdit(row, index) {
      this.jobForm.dialogVisible = true
      this.jobForm.index = index
      this.jobForm.time = row.time
      this.jobForm.unit = row.unit
      this.jobForm.position = row.position
    },
    memberForResumesDel(row, index) {
      this.$confirm('确定要删除该条工作简历吗？', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addForm1.unitReprstPreservePo.memberForResumes.splice(index, 1)
        this.$message.success('删除成功')
      })
    },
    closeJobForm() {
      this.$refs.jobForm.resetFields()
      this.jobForm.index = ''
    },
    // 人物
    postFormAdd(type) {
      this.postForm.type = type
      this.postForm.dialogVisible = true
    },
    postFormSubmit() {
      this.$refs.postForm.validate(valid => {
        if (!valid) return false
        if (this.postForm.type == 2) {
          if (this.postForm.index === '') {
            this.addForm1.unitPreservePos.post2.push({
              name: this.postForm.name,
              nowPosition: this.postForm.nowPosition,
              pastPosition: this.postForm.pastPosition,
              type: this.postForm.type
            })
          } else {
            this.addForm1.unitPreservePos.post2[this.postForm.index].name = this.postForm.name
            this.addForm1.unitPreservePos.post2[this.postForm.index].nowPosition = this.postForm.nowPosition
            this.addForm1.unitPreservePos.post2[this.postForm.index].pastPosition = this.postForm.pastPosition
          }
        } else if (this.postForm.type == 4) {
          if (this.postForm.index === '') {
            this.addForm1.unitPreservePos.post4.push({
              name: this.postForm.name,
              nowPosition: this.postForm.nowPosition,
              pastPosition: this.postForm.pastPosition,
              type: this.postForm.type
            })
          } else {
            this.addForm1.unitPreservePos.post4[this.postForm.index].name = this.postForm.name
            this.addForm1.unitPreservePos.post4[this.postForm.index].nowPosition = this.postForm.nowPosition
            this.addForm1.unitPreservePos.post4[this.postForm.index].pastPosition = this.postForm.pastPosition
          }
        }
        this.postForm.dialogVisible = false
        this.postForm.index = ''
      })

    },
    postFormEdit(row, index) {
      this.postForm.dialogVisible = true
      this.postForm.index = index
      this.postForm.name = row.name
      this.postForm.nowPosition = row.nowPosition
      this.postForm.pastPosition = row.pastPosition
      this.postForm.type = row.type
      console.log(this.postForm)
    },
    postFormDel(row, index) {
      this.$confirm('确定要删除该条工作简历吗？', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (row.type == 2) {
          this.addForm1.unitPreservePos.post2.splice(index, 1)
        } else if (row.type == 4) {
          this.addForm1.unitPreservePos.post4.splice(index, 1)
        }
        this.$message.success('删除成功')
      })
    },
    closePostForm() {
      this.$refs.postForm.resetFields()
      this.postForm.index = ''
    },
    UploadFileFun(val, type) {
      console.log(val, type)
      this.addFormFile[type] = val
    },
    // 金额
    getValueFirst(val, type) {
      if (type == 1) {
        this.addForm1.unitPreservePo.unitEnterprisePo.registeredFunds = val
      } else if (type == 2) {
        this.addForm1.unitPreservePo.unitEnterprisePo.annualRevenue = val
      } else if (type == 3) {
        this.addForm1.unitPreservePo.unitEnterprisePo.totalFunds = val
      }
    },
    prev() {
      this.activeName = this.activeName == 2 ? '1' : '2'
      const timer = setInterval(function () {
        document.getElementById('home-main').scrollTop -= 30
        if (document.getElementById('home-main').scrollTop <= 0) {
          clearInterval(timer)
        }
      }, 10)

    },
    next() {
      this.activeName = this.activeName == 1 ? '2' : '3'
      const timer = setInterval(function () {
        document.getElementById('home-main').scrollTop -= 100
        if (document.getElementById('home-main').scrollTop <= 0) {
          clearInterval(timer)
        }
      }, 10)

    },
    again(flag) {
      // 1 退回修改  2 重新填写
      this.formShow = true
      if (flag === 1) {
        this.getDetail(this.id)
      }
    },
  }
}

</script>

<style lang="less">
#home-main {
  height: 100vh;
  overflow-y: auto;
}
.tab {
  padding: 20px 0;
  .el-tabs__header.is-left {
    display: none;
  }
}
</style>

<style lang="less" scoped>

.form-apply {
  width: 1200px;
  margin: auto;
}
.title {
  text-align: center;
  font-size: 20px;
  // padding: 30px 0 10px 0;
}
.form-box {
  padding: 50px 100px;
}
.form-item-from-box {
  padding-bottom: 20px;
}
.topTitle {
  width: 100%;
  height: 30px;
  box-shadow: inset 0px -1px 0px 0px #1e63b0;
  margin-bottom: 20px;

  span:first-child {
    width: 3px;
    height: 14px;
    background: #1e63b0;
    display: inline-block;
    border-radius: 1px;
  }

  span~span {
    margin-left: 10px;
    width: 126px;
    height: 48px;
    font-size: 14px;
    font-weight: 600;
    color: #1e63b0;
    line-height: 20px;
  }
}

.state-title {
  // padding: 100px 0;
  padding-top: 10px;
  text-align: center;
}

.record-box {
  width: 800px;
  margin: auto;
}

.record-list {
  max-height: 600px;
  overflow-y: auto;
}

</style>